<template>
  <div class="container">
    <div class="text-end p-3">
      <c-primary-button to="/admin/dependencias/crear">
        Crear dependencia
      </c-primary-button>
    </div>
    <c-card title="Dependencias">
      <table class="table table-striped">
        <thead>
          <tr>
            <th>Dependencia</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="dependency in dependencies" :key="dependency.id">
            <td>{{ dependency.name }}</td>
            <td class="text-nowrap">
              <router-link
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                :to="`/admin/dependencias/${dependency.id}/editar`"
                title="Editar"
                ><i class="fa-solid fa-pen"></i
              ></router-link>
              <a
                class="btn btn-secondary btn-sm ms-2 d-inline-block"
                href="javascript:void(0)"
                @click="deleteDependency(dependency.id)"
                title="Eliminar"
                ><i class="fa-solid fa-trash-can"></i
              ></a>
            </td>
          </tr>
        </tbody>
      </table>
    </c-card>
  </div>
</template>
<script>
import CCard from "@/components/CCard.vue";
import CPrimaryButton from "@/components/CPrimaryButton.vue";
import { all, remove } from "@/services/dof_dependencies";
import { showNotification } from "@/services/notification";

export default {
  components: {
    CCard,
    CPrimaryButton,
  },
  data() {
    return {
      dependencies: [],
    };
  },
  mounted() {
    this.getDependencies();
  },
  methods: {
    getDependencies() {
      all().then((response) => {
        this.dependencies = response.data;
      });
    },
    deleteDependency(id) {
      const removeRecord = confirm("¿Estás seguro de eliminar el registro?");
      if (!removeRecord) {
        return;
      }
      remove(id).then(() => {
        showNotification(204, "success", "El registro fue eliminado.");
        this.getDependencies();
      });
    },
  },
};
</script>
